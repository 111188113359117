import { Box, BoxProps } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import React, { ReactNode, useMemo } from 'react'
import { onlyText } from 'react-children-utilities'
import { isHebrew } from '../../data/hebrew_regex'
import clsx from 'clsx'

const useStyles = makeStyles({ rtl: { direction: 'rtl' } })

export const MaybeRtlBox = ({ className, ...props }: BoxProps) => {
  const { direction } = useTheme()
  const directionLtr = direction === 'ltr'
  const text = useMemo(() => onlyText(props.children), [props.children])
  const isTextHebrew = useMemo(() => isHebrew(text), [text])
  const classes = useStyles()
  const xored = (directionLtr && isTextHebrew) || (!directionLtr && !isTextHebrew)
  return <Box {...props} className={clsx(className, xored ? classes.rtl : undefined)} />
}

export const MaybeRtl = ({ children }: { children: ReactNode }) => {
  const { direction } = useTheme()
  const directionLtr = direction === 'ltr'
  const text = useMemo(() => onlyText(children), [children])
  const isTextHebrew = useMemo(() => isHebrew(text), [text])
  const classes = useStyles()
  const xored = (directionLtr && isTextHebrew) || !directionLtr || !isTextHebrew
  return <span className={xored ? classes.rtl : undefined}>{children}</span>
}
