import * as Types from '../../generated/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetConferencesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetConferencesQuery = (
  { __typename?: 'Query' }
  & { conferences: Array<(
    { __typename?: 'Conference' }
    & Pick<Types.Conference, 'id' | 'name' | 'shortName' | 'participantCount' | 'settings' | 'archived'>
  )> }
);


export const GetConferencesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetConferences"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"conferences"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"shortName"}},{"kind":"Field","name":{"kind":"Name","value":"participantCount"}},{"kind":"Field","name":{"kind":"Name","value":"settings"}},{"kind":"Field","name":{"kind":"Name","value":"archived"}}]}}]}}]} as unknown as DocumentNode<GetConferencesQuery, GetConferencesQueryVariables>;