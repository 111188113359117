import React, { useMemo, useState } from 'react'
import Link from 'next/link'
import { routes } from '../../src/routes'
import { Loading } from '../../src/components/common/Loading'
import { Box, Button, MenuItem, Typography, useMediaQuery } from '@material-ui/core'
import { useQuery } from '@apollo/client/react/hooks'
import { LayoutType } from '../../src/components/common/LayoutType'
import { Column } from 'react-table'
import { FullHeightPageTitle } from '../../src/components/common/PageTitle'
import EnhancedTable from '../../src/components/table/components/EnhancedTable'
import { GetConferencesDocument, GetConferencesQuery } from './index.generated'
import { ConferenceSettings } from '../../src/data/interfaces/ConferenceSettings'
import { formatDateHuman, parseDateWire } from '../../src/lib/WireDateFormat'
import { useTheme } from '@material-ui/core/styles'
import { CurrentAdminUserDocument } from '../../src/components/layout/Layout.generated'
import { BoundIconMenu } from '../../src/components/common/BoundMenu'
import { MaybeRtlBox } from '../../src/components/common/MaybeRtlBox'
import Check from '@material-ui/icons/Check'

type ConferenceIndexData = Pick<
  GetConferencesQuery['conferences'][number],
  'id' | 'name' | 'shortName' | 'participantCount'
> &
  Pick<ConferenceSettings['dates'], 'startDate' | 'endDate'>

function Index() {
  const userPermissions = useCurrentUserPermissions()
  const isUserAdmin = userPermissions?.userAdmin
  const [showArchived, setShowArchived] = useState(false)

  return (
    <FullHeightPageTitle
      title={showArchived ? 'Archived Conferences' : 'Conferences'}
      Button={
        <>
          <Link {...routes.newConference} passHref>
            <Button color={'primary'}>New Conference</Button>
          </Link>
          <BoundIconMenu>
            {isUserAdmin ? (
              <Link {...routes.admin.users.list} passHref>
                <MenuItem>Users</MenuItem>
              </Link>
            ) : null}
            <MenuItem onClick={() => setShowArchived((v) => !v)}>
              {showArchived ? (
                <Box mr={1} display={'flex'}>
                  <Check />
                </Box>
              ) : null}
              Show Archived Conferences
            </MenuItem>
          </BoundIconMenu>
        </>
      }
    >
      <ConferencesTable showArchived={showArchived} />
    </FullHeightPageTitle>
  )
}

const ConferencesTable = ({ showArchived = false }: { showArchived: boolean }) => {
  const { data } = useQuery(GetConferencesDocument)
  const conferences = data?.conferences
    .filter((v) => (showArchived ? v.archived : !v.archived))
    .map((v) => {
      const {
        dates: { startDate, endDate },
      } = v.settings
      return {
        ...v,
        startDate,
        endDate,
      }
    })
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('md'))
  const columns: Column<ConferenceIndexData>[] = useMemo(
    () => [
      {
        id: 'id',
        accessor: 'id',
        Header: 'ID',
      },
      {
        accessor: 'name',
        Header: 'Name',
        width: 400,
        Cell: ({
          row: {
            values: { name, shortName },
          },
        }) => (
          <Link {...routes.participants(shortName)} passHref>
            <a>
              <MaybeRtlBox>
                <Typography variant={'subtitle1'}>{name}</Typography>
              </MaybeRtlBox>
            </a>
          </Link>
        ),
      },
      {
        width: 100,
        accessor: 'shortName',
        Header: 'Short Name',
      },
      ...(smUp
        ? ([
            {
              width: 50,
              accessor: 'participantCount',
              Header: 'Participants',
            },
            {
              width: 50,
              Header: 'Start Date',
              accessor: ({ startDate }) => startDate && parseDateWire(startDate),
              Cell: ({ value }: { value: Date }) => (value ? formatDateHuman(value) : ''),
              sortType: 'datetime',
            },
          ] as Column<ConferenceIndexData>[])
        : []),
    ],
    [smUp]
  )
  if (!conferences) return <Loading />

  return (
    <EnhancedTable
      tableId={'conferences'}
      columns={columns}
      data={conferences}
      toolbarOptions={{ onlySearch: true }}
      initialState={{
        sortBy: [{ id: 'id', desc: true }],
        hiddenColumns: ['id'],
      }}
      hideSelection
      hideHeaderFilters
    />
  )
}

export const useCurrentUserPermissions = () => {
  const { data } = useQuery(CurrentAdminUserDocument)
  return data?.myUser?.permissions
}

Index.layoutType = LayoutType.NONE

export default Index
