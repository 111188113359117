import MoreVert from '@material-ui/icons/MoreVert'
import React, { ComponentType, ReactNode } from 'react'
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'
import { Button, IconButton, Menu } from '@material-ui/core'
import { PopupState } from 'material-ui-popup-state/core'
import isFunction from 'lodash/isFunction'

export const BoundIconMenu = ({
  children,
  Icon = <MoreVert />,
  Component = IconButton,
}: {
  children: ReactNode | ((popupState: PopupState) => ReactNode)
  Icon?: ReactNode
  Component?: ComponentType<ReturnType<typeof bindTrigger>>
}) => {
  return (
    <BoundMenu label={Icon} Component={Component}>
      {children}
    </BoundMenu>
  )
}

export const BoundMenu = ({
  children,
  label,
  Component = Button,
}: {
  children: ReactNode | ((popupState: PopupState) => ReactNode)
  label: ReactNode
  Component?: ComponentType<ReturnType<typeof bindTrigger>>
}) => {
  const menuState = usePopupState({ variant: 'popper', popupId: 'menu' })
  return (
    <>
      <Component {...bindTrigger(menuState)}>{label}</Component>
      <Menu {...bindMenu(menuState)}>{isFunction(children) ? children(menuState) : children}</Menu>
    </>
  )
}
