export const SEPARATOR = ':' as const

export enum Occupancy {
  single = 'SINGLE',
  double = 'DOUBLE',
  triple = 'TRIPLE',
  quad = 'QUAD',
}

export const isPromise = (v: any): v is Promise<any> => {
  return typeof v?.then === 'function'
}

export const isNullOrUndefined = <T>(v?: T extends Promise<any> ? never : T): v is undefined => {
  return v === undefined || v === null
}

export function throwIfNull<T>(
  v?: T extends Promise<any> ? never : T extends null | undefined ? never : T,
  message = 'Not found'
): NonNullable<T> {
  if (isNullOrUndefined(v) || isPromise(v)) {
    throw new Error(message)
  }

  return v as any
}

export const BUCKET_URL = 'https://storage.googleapis.com/targetconferences_userfiles/'
