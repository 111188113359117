import * as Types from '../../../generated/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type VersionQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type VersionQuery = (
  { __typename?: 'Query' }
  & Pick<Types.Query, 'version'>
);

export type CurrentAdminUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentAdminUserQuery = (
  { __typename?: 'Query' }
  & { myUser?: Types.Maybe<(
    { __typename?: 'User' }
    & AdminUserDataFragment
  )> }
);

export type AdminUserDataFragment = (
  { __typename?: 'User' }
  & Pick<Types.User, 'id' | 'data' | 'permissions' | 'name' | 'email'>
);

export const AdminUserDataFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AdminUserData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"data"}},{"kind":"Field","name":{"kind":"Name","value":"permissions"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}}]} as unknown as DocumentNode<AdminUserDataFragment, unknown>;
export const VersionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Version"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"version"}}]}}]} as unknown as DocumentNode<VersionQuery, VersionQueryVariables>;
export const CurrentAdminUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CurrentAdminUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"myUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AdminUserData"}}]}}]}},...AdminUserDataFragmentDoc.definitions]} as unknown as DocumentNode<CurrentAdminUserQuery, CurrentAdminUserQueryVariables>;