import {
  addDays,
  differenceInDays,
  format,
  isAfter,
  isBefore,
  isDate,
  max,
  min,
  parse,
  parseISO,
} from 'date-fns'
import times from 'lodash/times'

export const WIRE_DATE_FORMAT = 'yyyy-MM-dd'
export const HUMAN_DATE_FORMAT = 'dd/MM/yyyy'

export const formatDateWire = (date: Date) => format(date, WIRE_DATE_FORMAT)

export const parseDateWire = (date: string) => {
  const parsedDate = parseISO(date)
  return isDate(parsedDate) && !isNaN(parsedDate.getTime()) ? parsedDate : parseISO(date)
}

export const parseDateWireILTime = (date: string) => {
  const parsedDate = parseISO(date + ' +02:00')
  return isDate(parsedDate) && !isNaN(parsedDate.getTime()) ? parsedDate : parseISO(date)
}

export const isWireDateValid = (date: string) => isActuallyADate(parseDateWire(date))

export const isActuallyADate = (parsedDate?: Date) => {
  return parsedDate && isDate(parsedDate) && !isNaN(parsedDate.getTime())
}

export const formatDateHuman = (date: Date) => {
  try {
    return format(date, HUMAN_DATE_FORMAT)
  } catch (e) {
    // console.log('Date format error', e, date)
    return ''
  }
}
export const formatWireDateHuman = (dateStr: string) => formatDateHuman(parseDateWire(dateStr))

export const parseTime = (time: string) => {
  if (!time) return null
  return parse(time, 'kk:mm', new Date())
}

export const formatTime = (date: Date) => {
  if (!date) return ''
  try {
    return format(date, 'kk:mm')
  } catch (e) {
    // console.log('Date format error', e, date)
    return ''
  }
}

export const formatDateTimeHuman = (date: Date) => {
  return [formatDateHuman(date), formatTime(date)].filter((v) => v).join(', ')
}

export const isBeforeString = (dateStr: string, dateToCompareStr: string) => {
  return isBefore(parseISO(dateStr), parseISO(dateToCompareStr))
}

export const isAfterString = (dateStr: string, dateToCompareStr: string) => {
  return isAfter(parseISO(dateStr), parseISO(dateToCompareStr))
}

export const addDayStr = (dateStr: string) => formatDateWire(addDays(parseDateWire(dateStr), 1))

export const timesDatesString = (
  startDateString?: string | null,
  endDateString?: string | null,
  includeEndDate = false
): string[] => {
  if (!startDateString || !endDateString) {
    return []
  }

  const startDate = parseISO(startDateString)
  const endDate = parseISO(endDateString)
  return timesDates(startDate, endDate, includeEndDate).map((v) => formatDateWire(v))
}

export const getNumOfNights  =(checkIn: Date, checkOut: Date) => {
  return differenceInDays(checkOut, checkIn)
}

export const getNumOfNightsStr  =(checkIn: string, checkOut: string) => {
  return getNumOfNights(parseDateWire(checkIn), parseDateWire(checkOut))
}

export const timesDates = (startDate: Date, endDate: Date, includeEndDate = false): Date[] => {
  const numberOfDays = differenceInDays(endDate, startDate) + (includeEndDate ? 1 : 0)
  return times(numberOfDays).map((i) => addDays(startDate, i))
}

export const checkInCheckout = (dates?: Date[]) => {
  if (!dates?.length) return {}
  const minDate = min(dates)
  const maxDate = max(dates)
  return {
    checkIn: minDate && formatDateWire(minDate),
    checkOut: maxDate && formatDateWire(addDays(maxDate, 1)),
  }
}

export const checkInCheckoutStr = (dateStrings?: string[]) => {
  if (!dateStrings?.length) return {}
  const dates = dateStrings.map((v) => parseISO(v))
  return checkInCheckout(dates)
}

export const FORM_WIRE_FIELD_PROPS = {
  parse: (v: Date) => {
    return isDate(v) && !isNaN(v as any) ? format(v, WIRE_DATE_FORMAT) : v || undefined
  },
  format: (v?: string) => {
    return v ? parseISO(v) : null
  },
}
